import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login_user: {},
    flashMessage: {
      color: 'success',
      message: null
    },
    point_rate: [ // #[ ]バックエンド管理？
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5
    ]
  },
  getters: {
    loginUserInfo: state => {
      return state.login_user
    },
    getAppSettings: state => {
      return state.login_user?.app_settings
    },
    getUseDirectionManagement: state => {
      return state.login_user?.app_settings?.use_direction_management
    },
    getAllStatus: state => {
      return state.login_user?.app_settings?.status
    },
    getAllDirectionStatus: state => {
      return state.login_user?.app_settings?.direction_status
    },
    defaultDateFormat: state => {
      return state.login_user?.app_settings?.default_date_format
    },
    getVisualRelayUrl: state => {
      return state.login_user?.app_settings?.visual_relay_url
    },
    getAdminCompanyHoliday: state => {
      return state.login_user?.app_settings?.admin_company_holiday
    }
  },
  mutations: {
    setLoginUser (state, payload) {
      state.login_user = payload
    },
    setLoginUserName (state, payload) {
      state.login_user.name = payload // NOTE: データベースには保存されない一次的な変更
    },
    setFlashSuccess (state, payload) {
      state.flashMessage.color = 'success'
      state.flashMessage.message = payload
    },
    setFlashError (state, payload) {
      state.flashMessage.color = 'error'
      state.flashMessage.message = payload
    },
    setAdminCompanyHoliday (state, payload) {
      state.login_user.app_settings.admin_company_holiday = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
