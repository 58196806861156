<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import '/src/scss/_tokoton.scss';
  @import '/src/scss/_common.scss';
</style>
